.xm-preview-image[data-v-4652c02a] {
  width: 100px;
  height: 100px;
  position: relative;
  cursor: pointer;
}
.xm-preview-image .small-image-container > img[data-v-4652c02a] {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.xm-preview-image .big-image-container[data-v-4652c02a] {
  width: 230px;
  height: 230px;
  object-fit: contain;
  display: none;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.xm-preview-image .big-image-container img[data-v-4652c02a] {
  object-fit: contain;
}