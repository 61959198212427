.xm-upload[data-v-b5152ac2] {
  color: #666;
  font-size: 14px;
}
.xm-upload[data-v-b5152ac2] .el-upload {
  text-align: left;
}
.xm-upload[data-v-b5152ac2] .el-upload .el-upload-dragger {
  border: 1px dashed #ccc;
  border-radius: 0;
}
.xm-upload[data-v-b5152ac2] .el-upload .el-upload-dragger .el-upload__text {
  color: #999;
  letter-spacing: -0.01px;
}
.xm-upload .rule-tip[data-v-b5152ac2] {
  line-height: 22px;
  color: #666;
  font-size: 14px;
  letter-spacing: -0.01px;
}
.xm-upload .flex-between[data-v-b5152ac2] {
  display: flex;
  justify-content: space-between;
}
.xm-upload .flex-align-center[data-v-b5152ac2] {
  display: flex;
  align-items: center;
}
.xm-upload .overflow-line[data-v-b5152ac2] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.xm-upload .hover-color[data-v-b5152ac2] {
  cursor: pointer;
}
.xm-upload .hover-color[data-v-b5152ac2]:hover {
  color: #026bff !important;
}
.xm-upload .already-upload-file[data-v-b5152ac2] {
  padding-top: 6px;
  width: 360px;
}
.xm-upload .already-upload-file > div[data-v-b5152ac2]:last-child {
  padding-bottom: 0;
}
.xm-upload .already-upload-file .success-list[data-v-b5152ac2] {
  padding-bottom: 10px;
  line-height: 22px;
}
.xm-upload .already-upload-file .success-list .left-side[data-v-b5152ac2] {
  letter-spacing: -0.01px;
  cursor: pointer;
}
.xm-upload .already-upload-file .success-list .left-side[data-v-b5152ac2] .xm-preview-image {
  margin-right: 4px;
}
.xm-upload .already-upload-file .success-list .left-side[data-v-b5152ac2] .xm-preview-image img {
  border: 1px solid #ccc;
}
.xm-upload .already-upload-file .success-list .left-side img[data-v-b5152ac2],
.xm-upload .already-upload-file .success-list .left-side video[data-v-b5152ac2] {
  margin-right: 4px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  border: 1px solid #ccc;
}
.xm-upload .already-upload-file .success-list .left-side[data-v-b5152ac2]:hover {
  color: #026bff;
}
.xm-upload .already-upload-file .success-list .right-side[data-v-b5152ac2] {
  justify-content: flex-end;
}
.xm-upload .already-upload-file .success-list .right-side .upload-success[data-v-b5152ac2] {
  margin: 0 10px 0 20px;
  color: #67c23a;
  font-size: 14px;
}
.xm-upload .already-upload-file .success-list .right-side .del-file[data-v-b5152ac2] {
  width: 2em;
  flex: 1;
}
.xm-upload .padding-top6[data-v-b5152ac2] {
  padding-top: 6px !important;
}
.xm-upload .progress-upload-file[data-v-b5152ac2] {
  padding-top: 10px;
  width: 360px;
}
.xm-upload .progress-upload-file .left-side img[data-v-b5152ac2],
.xm-upload .progress-upload-file .left-side video[data-v-b5152ac2] {
  margin-right: 4px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  border: 1px solid #ccc;
}
.xm-upload .progress-upload-file .right-side[data-v-b5152ac2] {
  flex: 1;
  justify-content: flex-end;
}
.xm-upload .progress-upload-file[data-v-b5152ac2] .el-progress {
  margin: 0 38px 0 20px;
}
.xm-upload .progress-upload-file[data-v-b5152ac2] .el-progress .el-progress-circle {
  width: 14px !important;
  height: 14px !important;
}