.margin-auto[data-v-c39fdc8c] {
  margin: 0 auto;
  background: #ffffff;
}
[data-v-c39fdc8c] .el-cascader-panel .in-checked-path {
  background: rgba(2, 107, 255, 0.1);
}
[data-v-c39fdc8c] .el-cascader-panel .in-checked-path .el-cascader-node__label {
  color: #026bff;
}
[data-v-c39fdc8c] .el-cascader-panel .el-cascader-node__label {
  color: #808080;
}
[data-v-c39fdc8c] .el-cascader-panel .el-cascader-node:hover {
  background: rgba(2, 107, 255, 0.1);
}