.address-selector[data-v-538b7ee4] {
  position: relative;
  width: 200px;
}
.address-selector *[data-v-538b7ee4] {
  border: 0px;
}
.address-selector ul[data-v-538b7ee4] {
  padding-left: 1em;
}
.address-selector li[data-v-538b7ee4] {
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.address-selector li.active[data-v-538b7ee4],
.address-selector span.active[data-v-538b7ee4] {
  color: #026bff;
}
.address-selector li[data-v-538b7ee4]:hover {
  color: #026bff;
}
.address-selector[data-v-538b7ee4] .el-input.el-input--suffix {
  width: 200px;
}
.address-selector[data-v-538b7ee4] .el-select .el-input.is-focus .el-input__inner,
.address-selector[data-v-538b7ee4] .el-select .el-input__inner:focus {
  border-color: #026bff;
}
.address-selector[data-v-538b7ee4] .el-select-dropdown {
  visibility: hidden;
}
.address-selector[data-v-538b7ee4] .popper__arrow {
  visibility: hidden;
}
.address-selector .no-search-input[data-v-538b7ee4] {
  height: 290px;
  overflow: auto;
  padding-left: 5px;
}
.address-selector .no-search-input[data-v-538b7ee4]::-webkit-scrollbar-track {
  background: none;
}
.address-selector[data-v-538b7ee4] .el-input {
  height: 40px;
  margin-bottom: 10px;
}
.address-selector[data-v-538b7ee4] .el-input__prefix {
  display: inline-block;
}
.address-selector[data-v-538b7ee4] .search-icon {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.address-selector[data-v-538b7ee4] .el-tabs .el-input__inner {
  width: 280px;
  margin-left: -5px;
  border-radius: 0px 0px 0px 0px;
  background-color: #f7f7f7;
  font-size: 12px;
  color: #808080;
}
.address-selector[data-v-538b7ee4] .el-tabs {
  visibility: visible;
  position: absolute;
  top: -8px;
  left: -6px;
  z-index: 2;
  width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.address-selector[data-v-538b7ee4] .el-tabs * {
  font-size: 14px;
  color: #666;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header {
  border: 0px;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__content {
  padding-bottom: 10px;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  border-left-color: transparent;
  border-right-color: transparent;
}
.address-selector[data-v-538b7ee4] .el-tabs__item {
  width: 100px;
  text-align: center;
}
.address-selector[data-v-538b7ee4] .el-tabs__item:nth-last-of-type(1) {
  width: 101px;
}
.address-selector[data-v-538b7ee4] .el-tabs__nav-prev,
.address-selector .el-tabs__nav-next[data-v-538b7ee4] {
  display: none;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item {
  margin-left: 0px;
}
.address-selector[data-v-538b7ee4] .el-tabs__content {
  padding-right: 2px;
  padding-top: 10px;
  height: 310px;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #808080;
}
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
.address-selector[data-v-538b7ee4] .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
  color: #4d4d4d;
}
.address-selector[data-v-538b7ee4] .el-tabs__item.is-top {
  background-color: #f2f2f2;
  color: #808080;
}
.address-selector[data-v-538b7ee4] .el-tabs__item.is-active {
  background-color: #ffffff;
  color: #4d4d4d;
}
.address-selector .country-list-wrapper[data-v-538b7ee4] {
  height: 240px;
  overflow: auto;
}
.address-selector .country-list-wrapper[data-v-538b7ee4]::-webkit-scrollbar-track {
  background: none;
}
.address-selector .country-groups > span[data-v-538b7ee4] {
  color: #666;
  padding-right: 5px;
  margin-top: 5px;
}
.address-selector .country-groups > span.active[data-v-538b7ee4] {
  color: #026bff;
}
.address-selector .country-groups .country-group-divider[data-v-538b7ee4] {
  border: 1px solid #e6e6e6;
  display: inline-block;
  width: 250px;
  margin-bottom: 0.33em;
}