.outerdiv .cover-mask[data-v-1ddfaae8] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.25);
}
.outerdiv .cover-mask .preview-dialog-close-header[data-v-1ddfaae8] {
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 201;
  text-align: right;
}
.outerdiv .cover-mask .preview-dialog-close-header .dialog-close-icon[data-v-1ddfaae8] {
  width: 32px;
  height: 32px;
}
.outerdiv .cover-mask .preview-dialog-container[data-v-1ddfaae8] {
  width: 800px;
  max-width: 90vw;
  height: 900px;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.outerdiv .cover-mask .preview-dialog-container header[data-v-1ddfaae8] {
  height: 56px;
  padding: 20px;
}
.outerdiv .cover-mask .preview-dialog-container header span[data-v-1ddfaae8] {
  font-size: 16px;
  color: #111;
}
.outerdiv .cover-mask .preview-dialog-container header .dialog-close-icon[data-v-1ddfaae8] {
  float: right;
  font-size: 24px;
  color: #9d9d9d;
  cursor: pointer;
}
.outerdiv .cover-mask .preview-dialog-container main[data-v-1ddfaae8] {
  width: 100%;
  height: calc(100% - 56px);
  border-bottom: 20px solid #fff;
}
.outerdiv .cover-mask .preview-dialog-container main iframe[data-v-1ddfaae8] {
  width: 100%;
  height: 100%;
}
.outerdiv .cover-mask .preview-dialog-container.wide-dialog[data-v-1ddfaae8] {
  width: 1440px;
  height: 920px;
}
.outerdiv .cover-mask .preview-dialog-container.wide-dialog main[data-v-1ddfaae8] {
  height: 100%;
  border: none;
  padding: 0px;
}