.tip[data-v-4b69d181] {
  width: 1160px;
  background: #f7f7f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 0.8;
  margin: 0 auto;
  display: flex;
  padding: 10px 20px;
}
.tip .tip_lab[data-v-4b69d181] {
  font-size: 14px;
  color: #4d4d4d;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  margin-left: 10px;
  flex-shrink: 0;
}
.tip .tip_text1[data-v-4b69d181] {
  font-size: 12px;
  color: #808080;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  margin-left: 3px;
  line-height: 21px;
}
.tip .tip_text2[data-v-4b69d181] {
  font-size: 12px;
  color: #808080;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  margin-left: 3px;
  line-height: 21px;
}
.tip .tip_img[data-v-4b69d181] {
  margin-top: 2px;
}