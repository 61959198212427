.xm-title[data-v-2b190fb8] {
  padding: 20px 0;
  background: #f7f7f7;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  color: #111111;
}
.xm-title .content[data-v-2b190fb8] .el-popover__reference-wrapper img {
  vertical-align: 2px;
  width: 20px;
  height: 20px;
}