@font-face {
  font-family: "iconfont"; /* Project id 4141844 */
  src: url('iconfont.eot?t=1694776594552'); /* IE9 */
  src: url('iconfont.eot?t=1694776594552#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff2?t=1694776594552') format('woff2'),
       url('iconfont.woff?t=1694776594552') format('woff'),
       url('iconfont.ttf?t=1694776594552') format('truetype'),
       url('iconfont.svg?t=1694776594552#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e618";
}

.icon-guanbi-cha-fill:before {
  content: "\e647";
}

.icon-a-1111:before {
  content: "\e617";
}

.icon-arrow-up:before {
  content: "\e612";
}

.icon-Frame:before {
  content: "\e637";
}

.icon-upload:before {
  content: "\e635";
}

.icon-shanchu:before {
  content: "\e636";
}

