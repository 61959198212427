.el-radio-group .el-radio-button__inner {
  padding: 9px 12px;
  border-radius: 0;
}

.el-menu {
  border-right: 0;
}

.el-submenu .el-menu-item {
  min-width: 190px;
}

.el-pagination.is-background .el-pager li {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  color: #808080;
  font-weight: 400;
}

.el-pager li {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
}

.el-popover {
  min-width: 10px;
  padding: 15px 20px;
}

.el-pagination__editor .el-input__inner:focus {
  border: 1px solid #dcdfe6;
}

.el-input__inner {
  color: #808080;
  border: 1px solid #cccccc;
}

.el-input__inner {
  border-radius: 0px;
}

.el-textarea__inner {
  border-radius: 0px;
  border-color: #cccccc;
}

.el-select-dropdown__item:hover,
.el-select-dropdown__item.hover {
  background-color: rgba(2, 107, 255, 0.1);
}

.el-select-dropdown__item.selected {
  font-weight: 400;
}

.el-date-editor .el-range-separator,
.el-date-editor .el-range-input {
  color: #808080;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}

.el-button {
  border-radius: 0px;
}

.el-button--default {
  color: #666666;
  border-color: #ccc;
}

.el-scrollbar__wrap {
  max-height: 232px;
}

.el-dropdown-menu {
  min-width: 116px;
}

.el-table .cell {
  padding-left: 16px;
  padding-right: 0;
}