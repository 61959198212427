.xm-show-file-box[data-v-7b25df3a] {
  padding: 9px 0;
}
.xm-show-file-box .success-list[data-v-7b25df3a] {
  margin-top: 10px;
}
.xm-show-file-box .success-list .left-side[data-v-7b25df3a] {
  cursor: pointer;
}
.xm-show-file-box .success-list .left-side:hover .file-name[data-v-7b25df3a] {
  color: #026bff;
}
.xm-show-file-box .success-list .left-side img[data-v-7b25df3a] {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.xm-show-file-box .success-list .file-name[data-v-7b25df3a] {
  margin-left: 10px;
}
.xm-show-file-box .success-list[data-v-7b25df3a]:first-child {
  margin-top: 0;
}
.xm-show-file-box[data-v-7b25df3a] img {
  object-fit: contain !important;
}