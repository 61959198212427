.header-max[data-v-954d9984] {
  display: flex;
  height: 86px;
  border-bottom: 1px solid #eaebf0;
}
.header-max .head-box[data-v-954d9984] {
  display: flex;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}
.header-max .head-box .logo[data-v-954d9984] {
  height: 40px;
  cursor: pointer;
}
.header-max .head-box .logo img[data-v-954d9984] {
  object-fit: contain;
  height: 100%;
}
.header-max .head-box .margin-left32[data-v-954d9984] {
  margin-left: 32px;
}