@charset "UTF-8";
/* 遮罩层 */
.mask[data-v-26da1ec5] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

/* 弹窗 */
.box[data-v-26da1ec5] {
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;
  display: grid;
  grid-template-rows: 60px 1fr;
}
.box .box-header[data-v-26da1ec5] {
  background-color: #f2f2f2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: space-around;
  align-items: center;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #111111;
  padding: 20px;
}
.box .box-header .box-close-icon[data-v-26da1ec5] {
  justify-self: end;
  color: #808080;
  font-size: 24px;
  cursor: pointer;
}
.box .box-main[data-v-26da1ec5] {
  align-self: center;
  width: 100%;
  height: 100%;
}
.box .box-main[data-v-26da1ec5] .el-select .el-select-dropdown {
  position: absolute !important;
  top: 100% !important;
  left: 0px !important;
}